@font-face {
    font-family: "FuturaPT-Book";
    src: local("FuturaPT-Book"),
        url("./assets/fonts/FuturaPT-Book.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Bold";
    src: local("FuturaPT-Bold"),
        url("./assets/fonts/FuturaPT-Bold.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Demi";
    src: local("FuturaPT-Demi"),
        url("./assets/fonts/FuturaPT-Demi.ttf") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "FuturaPT-Light";
    src: local("FuturaPT-Light"),
        url("./assets/fonts/FuturaPT-Light.ttf") format("truetype");
    font-weight: bold;
}

body {
    margin: 0px!important;
}

input {
    border: 1px solid black;
}

select {
    border: 1px solid black;
}

.addProductTitle {
    font-size: 2.4rem;
    font-family: FuturaPT-Demi;
}

.h-55vh {
    height: 55vh;
}

.h-60px {
    height: 60px;
}

.loader-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width:480px)  { 
    .mobileGrid {
        display: grid!important;
    }
}
