.filterContainer {
    display: flex;
    justify-content: flex-start;
}

.filterSplit {
    display: grid;
    grid-template-columns: 80% 20%;
    margin: 0rem 1rem;
}

.mt-1 {
    margin-top: 1rem;
}

@media (max-width:480px)  { 
    .filterSplit {
        display: none;
    }
  }