.newProduct {
  flex: 4;
  margin-left: 100px;
  margin-top: 50px;
}

.addProductForm {
  margin-top: 10px;
}

.addProductButton {
  margin-top: 10px;
  padding: 7px 10px;
  border: none;
  border-radius: 10px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}


.updateProductButton {
  margin-top: 10px;
  max-width: 230px;
  min-width: 230px;
  padding: 7px 10px;
  border: none;
  border-radius: 10px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

