.newProduct {
    flex: 4;
    margin-left: 100px;
    margin-top: 50px;
  }
  
  .addProductForm {
    margin-top: 10px;
  }
  
  .addProductItem {
    width: 15vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .addProductItem > label {
    color: gray;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .addProductItem > input {
    padding: 5px;
  }
  
  .addProductItem > select {
    padding: 9px;
  }
  
  .addProductButton {
    margin-top: 10px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: darkblue;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }

  .remarksContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  @media (max-width:480px)  { 
    .addProductItem {
      width: 100%;
      display: flex;
      flex-direction: column;
    padding-bottom: 10px!important;
    padding-left: 2px!important;
      min-width: 50vw;
      margin:0px!important;
    }
  }

  @media (max-width:1000px)  { 
    .addProductItem {
      width: 15vw;
      display: flex;
      flex-direction: column;
    }

    .fullWidth {
      width:100%!important
    }
  }
  
  