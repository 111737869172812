.productList{
    flex: 4;
}

.productListItem{
    display: flex;
    align-items: center;
}

.productListImg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
  }

.productListDelete{
    color: red;
    cursor: pointer;
}

.addContainer {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
}

.transactionFilterContainer {
    display: grid;
    height: 30px;
    grid-template-columns: 25% 25% 25% 25%;
    padding-right: 20px;
}