.productList{
    flex: 4;
  }
  
  .productListItem{
    display: flex;
    align-items: center;
  }
  
  .campaignListImg {
    width: 46px;
    height: 46px;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
  }
  
  
  .productListDelete{
    color: red;
    cursor: pointer;
  }
  
  .addContainer {
    display: flex;
    justify-content: flex-end;
    margin: 1rem 0;
  }
  
  .updateTitle {
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  .updateProductButton {
    text-align:center;
    margin-top: 20px;
    padding: 7px 10px;
    border: none;
    border-radius: 10px;
    background-color: black;
    color: white;
    font-weight: 600;
    cursor: pointer;
  }