.required:after {
    content:" *";
    color: red;
  }

.black {
  color: black!important;
}

.mb-10 {
  margin-bottom: 10vh;
}

